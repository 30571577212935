import React, { useEffect } from "react";
import "../styles/Contact.css";
import Astro from "../assets/astro.png";
import Aos from "aos";
import "aos/dist/aos.css";

function Contact() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div className="contact" id="contacts">
      <div className="contact-container" data-aos="fade-left">
        <div className="contact-left-side">
          <h2 className="contact-title">Contact Me.</h2>
          <p className="contact-text">
            If you have any request, question or you just want to talk to me,
            don't hesitate to use this form!
          </p>
          <form
            className="contact-form"
            action="https://formsubmit.co/lucas.melo.form@gmail.com"
            method="POST"
          >
            <div className="first-row-box">
              <div className="inputBox name-box">
                <input type="text" required name="name" />
                <span>Name</span>
              </div>
              <div className="inputBox email-box">
                <input type="email" required name="email" />
                <span>Email</span>
              </div>
            </div>
            <div className="second-row-box">
              <div className="inputBox subject-box">
                <input type="text" required name="subject" />
                <span>Subject</span>
              </div>
            </div>
            <div className="third-row-box">
              <div className="inputBox message-box">
                <textarea type="text" required name="message" />
                <span>Message</span>
              </div>
            </div>
            <button type="submit" className="submit-button">
              <span className="contact-button">
                <a></a>
              </span>
            </button>
          </form>
        </div>
        <div className="contact-right-side">
          <ul>
            <li className="c1">
              <a href="https://www.facebook.com/cxafsdfsd/">
                <span>
                  <i className="fa-brands fa-facebook-f"></i>
                </span>
                Facebook
              </a>
            </li>
            <li className="c2">
              <a href="https://github.com/lucas-pmelo">
                <span>
                  <i className="fa-brands fa-github"></i>
                </span>
                Github
              </a>
            </li>
            <li className="c3">
              <a href="https://twitter.com/lucaspmelo_">
                <span>
                  <i className="fa-brands fa-twitter"></i>
                </span>
                Twitter
              </a>
            </li>
            <li className="c4">
              <a href="https://www.instagram.com/meloplucas_/">
                <span>
                  <i className="fa-brands fa-instagram"></i>
                </span>
                Instagram
              </a>
            </li>
            <li className="c5">
              <a href="https://www.youtube.com/channel/UCorwUmAguxofSAvFJMuevfA">
                <span>
                  <i className="fa-brands fa-youtube"></i>
                </span>
                Youtube
              </a>
            </li>
            <li className="c6">
              <a href="https://www.linkedin.com/in/lucas-melo-/">
                <span>
                  <i className="fa-brands fa-linkedin"></i>
                </span>
                Linkedin
              </a>
            </li>
          </ul>
          <div className="contact-astro">
            <img src={Astro}></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
