import React, { useState } from "react";
import "../styles/Header.css";
import { Link } from "react-scroll";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="header" id="header">
      <div className="header-left-side">
        <h2 className="logo">LUCAS MELO</h2>
      </div>
      <div className="header-right-side">
        <nav className="menu-nav">
          <ul className={`menu-list ${isOpen && "open"}`}>
            <li className="menu-item about-menu">
              <Link
                className="menuA"
                smooth
                spy
                to="about-me"
                // offset={-160}
                onClick={() => setIsOpen(!isOpen)}
              >
                About me
              </Link>
            </li>
            <li className="menu-item skills-menu">
              <Link
                className="menuA"
                smooth
                spy
                to="skills"
                // offset={-120}
                onClick={() => setIsOpen(!isOpen)}
              >
                My skills
              </Link>
            </li>
            <li className="menu-item projects-menu">
              <Link
                className="menuA"
                smooth
                spy
                to="projects"
                onClick={() => setIsOpen(!isOpen)}
              >
                Projects
              </Link>
            </li>
            <li className="menu-item contact-menu">
              <Link
                className="menuA"
                smooth
                spy
                to="contacts"
                onClick={() => setIsOpen(!isOpen)}
              >
                Contact
              </Link>
            </li>
          </ul>
          <div
            className={`menu-toggle ${isOpen && "open"}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="menu-bar"></div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
