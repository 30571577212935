import React, { useEffect, useRef } from "react";
import "../styles/UpButton.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-scroll";

gsap.registerPlugin(ScrollTrigger);

function UpButton() {
  const revealRefs = useRef(null);

  useEffect(() => {
    const el = revealRefs.current;
    gsap.fromTo(
      el,
      { autoAlpha: 0 },
      {
        autoAlpha: 1,
        duration: 1,
        scrollTrigger: {
          trigger: el,
          start: "top-=200",
          toggleActions: "play none none reverse"
        }
      }
    );
  }, []);

  return (
    <div className="upbutton" id="upbutton">
      <div className="helper"></div>
      <div className="upbutton-circle" ref={revealRefs}>
        <Link smooth to="header">
          <i className="fa-solid fa-arrow-up"></i>
        </Link>
      </div>
    </div>
  );
}

export default UpButton;
