import React, { useEffect } from "react";
import "../styles/Skills.css";
import Aos from "aos";
import "aos/dist/aos.css";

function Skills() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div className="skills" id="skills" data-aos="fade-left">
      <div className="skills-left-container">
        <h2 className="skills-title">Skills and Experience.</h2>
        <p className="skills-text">
          In the beginning of my professional journey, I started learning and
          trying to master all the concepts of Music Production, but during the
          pandemic, I felt a need to create and express myself not only through
          music, but with programming as well.
        </p>
        <p className="skills-text">
          In, January 2022, I started to learn and fall in love with Web
          Development, since then, I've become passionate about creating
          websites and searching for more creative solutions and designs.
        </p>
        <p className="skills-text">
          My goal is one day, be a Full-Stack developer, but I know I still have
          a long and rewarding journey, and I'm grateful for it.
        </p>
        <p className="skills-text">
          Visit my
          <a
            className="skills-linkedin"
            href="https://www.linkedin.com/in/lucas-melo-/"
          >
            LinkedIn
          </a>
          profile for more details.
        </p>
      </div>
      <div className="skills-right-container">
        <div className="skills-wrapper">
          <div className="shape-1"></div>
          <div className="shape-2"></div>
          <div className="skills-bar-container">
            <h2 className="skills-bar-title">MY CODING SKILLS</h2>
            <div className="skills-bar">
              <div className="details">
                <span>HTML</span>
                <span>90%</span>
              </div>
              <div className="bar">
                <div id="html-bar"></div>
              </div>
            </div>
            <div className="skills-bar">
              <div className="details">
                <span>CSS</span>
                <span>85%</span>
              </div>
              <div className="bar">
                <div id="css-bar"></div>
              </div>
            </div>
            <div className="skills-bar">
              <div className="details">
                <span>JavaScript</span>
                <span>72%</span>
              </div>
              <div className="bar">
                <div id="js-bar"></div>
              </div>
            </div>
            <div className="skills-bar">
              <div className="details">
                <span>React</span>
                <span>68%</span>
              </div>
              <div className="bar">
                <div id="react-bar"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
