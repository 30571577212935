import React from "react";
import "./App.css";
import Header from "./components/Header";
import Home from "./pages/Home";
import AboutMe from "./pages/AboutMe";
import Skills from "./pages/Skills";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import ScrollIcon from "./components/ScrollIcon";
import ScrollProgress from "./components/ScrollProgress";
import UpButton from "./components/UpButton";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
  }, []);

  return (
    <div className="App">
      <ScrollProgress />
      <Header />
      <ScrollIcon />
      <Home />
      <AboutMe />
      <UpButton />
      <Skills />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
