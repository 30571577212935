import React from "react";
import "../styles/Home.css";

function Home() {
  return (
    <div className="home-container">
      <section className="wrapper-starts">
        <div className="stars"></div>
        <div className="stars2"></div>
        <div className="starts3"></div>
      </section>
      <div className="content">
        <div className="home-left-side">
          <div className="text-container">
            <h1 className="title t1">Hi,</h1>
            <h1 className="title t2">I'm Lucas Melo, </h1>
            <h2 className="title t3">Front End Developer</h2>
          </div>
        </div>
        <div className="home-right-side">
          <div className="planet planetEarth">
            <div className="containerp">
              <div className="loader">
                <span></span>
              </div>
              <div className="earth"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
