import React from "react";
import "../styles/Footer.css";

function Footer() {
  return (
    <div className="footer">
      <h3 className="f1">&copy; 2022 lucasmelo</h3>
      <h3 className="f2">
        this website ia always improving, along with my experience in web
        development
      </h3>
      <h3 className="f3">Thanks for your visit!</h3>
      {/* <div className="footer-circle"></div> */}
    </div>
  );
}

export default Footer;
