import React from "react";
import "../styles/AboutMe.css";
import rocket from "../assets/rocket.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import CV from "../assets/CV.pdf";

function AboutMe() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="about-me" id="about-me">
      <div data-aos="fade-right" className="about-me-text-container">
        <h2 className="about-me-title">Who is Lucas?</h2>
        <div className="about-me-text-container-2">
          <p className="about-me-text">
            I'm a Front-End Developer located in Brazil. Currently, I am
            studying web development using React, and loving it. I started
            programming in January 2022 and since then, programming has become a
            passion. I am also a music lover, always looking for more knowledge
            about all the areas I love!
          </p>
          <span className="about-me-button">
            <a href={CV}></a>
          </span>
        </div>
      </div>
      <div data-aos="fade-right">
        <div className="about-me-animation">
          <div className="scene">
            <div className="stars-rocket"></div>
            <div className="rocket">
              <img src={rocket}></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
