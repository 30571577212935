import React from "react";
import "../styles/ScrollIcon.css";

function ScrollIcon() {
  return (
    <div className="scrollicon">
      <div class="scrolldown">
        <div class="chevrons">
          <div class="chevrondown"></div>
          <div class="chevrondown"></div>
        </div>
      </div>
    </div>
  );
}

export default ScrollIcon;
