import React, { useEffect } from "react";
import "../styles/Projects.css";
import Crypto from "../assets/crypto-prj.png";
import Dash from "../assets/dash-prj.png";
import Todo from "../assets/todo-prj.png";
import Pizza from "../assets/pizza-prj.png";
import Wordle from "../assets/wordle-prj.png";
import Dog from "../assets/dog-prj.png";
import Self from "../assets/self-prj.png";
import NFT from "../assets/nft-prj.png";
import Aos from "aos";
import "aos/dist/aos.css";

function Projects() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  Aos.init({
    disable: function () {
      var maxWidth = 800;
      return window.innerWidth < maxWidth;
    }
  });

  return (
    <div className="projects" id="projects">
      <div className="projects-container">
        <h2 data-aos="fade-right" className="projects-title">
          My Projects.
        </h2>
        <ul className="projects-list">
          <li className="projects-item" data-aos="zoom-in">
            <img className="blur" src={Crypto}></img>
            <div className="fade">
              <a href="https://cryptocurrency-lucas-melo.netlify.app">
                View Project
              </a>
            </div>
          </li>

          <li className="projects-item" data-aos="zoom-in">
            <img className="blur" src={Dash}></img>
            <div className="fade">
              <a href="https://dashboard-lucas-melo.netlify.app">
                View Project
              </a>
            </div>
          </li>

          <li className="projects-item" data-aos="zoom-in">
            <img className="blur" src={Todo}></img>
            <div className="fade">
              <a href="https://todolist-lucas-melo.netlify.app">View Project</a>
            </div>
          </li>
          <li className="projects-item" data-aos="zoom-in">
            <img className="blur" src={Pizza}></img>
            <div className="fade">
              <a href="https://pizza-lucas-melo.netlify.app">View Project</a>
            </div>
          </li>
          <li className="projects-item" data-aos="zoom-in">
            <img className="blur" src={Wordle}></img>
            <div className="fade">
              <a href="https://wordle-lucas-melo.netlify.app">View Project</a>
            </div>
          </li>
          <li className="projects-item" data-aos="zoom-in">
            <img className="blur" src={Dog}></img>
            <div className="fade">
              <a href="https://wisedog-lucas-melo.netlify.app">View Project</a>
            </div>
          </li>
          <li className="projects-item" data-aos="zoom-in">
            <img className="blur" src={Self}></img>
            <div className="fade">
              <a href="https://selfcare-lucas-melo.netlify.app">View Project</a>
            </div>
          </li>
          <li className="projects-item" data-aos="zoom-in">
            <img className="blur" src={NFT}></img>
            <div className="fade">
              <a href="https://nft-lucas-melo.netlify.app">View Project</a>
            </div>
          </li>
        </ul>
        <h3 className="projects-click">
          <a href="https://github.com/lucas-pmelo">Click for more...</a>
        </h3>
      </div>
    </div>
  );
}

export default Projects;
